.tos {
  display: flex;
  justify-content: center;
}
.tos h1,
.tos h2,
.tos h3,
.tos h4,
.tos h5,
.tos h6 {
  margin: 18px 0;
}
