.landing-page {
  color: #333;
  margin: 0;
  padding: 0;
  position: relative;
}
section {
  min-height: 400px;
}
.logo {
  font-size: 28px;
  height: 100%;
  margin: 0 12px;
}
.header-menu {
  color: #fff;
  display: flex;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}
.header-menu a {
  margin-right: 18px;
}
.promo-0, .promo-1, .promo-2, .promo-3 {
  align-items: center;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 550px;
  width: 100%;
}
.promo-1 {
  background-color: #202020;
  min-height: 250px;
}
.promo-2 {
  background-color: white;
  color: #202020;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 280px;
  padding: 102px 0px;
}
.promo-2 .content-container {
  /* color: #fff;  */
  font-size: 16px;
  line-height: 2.5em;
  max-width: 800px;
  padding: 32px;
}
.promo-3 {
  background-color: #161616;
  min-height: 250px;
  flex-direction: row;
}
.promo-3 .photo {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promo-3 .descr {
  width: 350px;
  height: 350px;
  padding: 22px;
  font-family: "DM Serif Text", sans-serif;
  font-style: italic;
  font-weight: 400;
}
.contact-section {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #000;
  color: #fff;
  flex-direction: column;
}
.contact-section .content-container {
  font-size: 12px;
  line-height: 2.5em;
  max-width: 800px;
  padding: 32px;
}
.content-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 888px) {

}