.lobby-item-important-info {
  position: absolute;
  top: 30px;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.lobby-item-normal-info div {
  margin: 12px 0;
}
.lobby-item-reelsetup, .lobby-item-type, .lobby-item-rtp {
  background: black;
  padding: 6px;
  border-radius: 9px;
  color: white;
  font-weight: 800;
  font-size: 10px;
  border: 2px solid #828282;
}